import React from 'react';
import { renderRoutesRecursively, useTranslate } from '../utils';
import * as shapes from './proptypes';
import Translate from './Translate';

import styles from './PageFooter.module.css';

const PageFooter = (props) => {
  const { resources, config, pages } = props;

  const translate = useTranslate(resources);

  return (
    <footer className={styles.footer}>
      <div>
        <Translate
          resources={resources}
          config={config}
          translate="common.company"
        />
        <Translate
          resources={resources}
          config={config}
          translate="common.openinghours"
        />
        <Translate
          resources={resources}
          config={config}
          translate="common.contact"
        />
      </div>
      <div>
        <Translate
          resources={resources}
          config={config}
          translate="common.routes"
        />
        {renderRoutesRecursively(pages, 'footer-items')}
      </div>
      {/* <CookieNotice
        className={styles.cookieNotice}
        ga="UA-76202457-3"
        cookie="accepted-cookies-heidpartner"
        message={translate('cookienotice.message')}
        buttonMessage={translate('cookienotice.button')}
        link={<Translate resources={resources} translate="cookienotice.link" />}
      /> */}
    </footer>
  );
};

PageFooter.propTypes = {
  pages: shapes.pages.isRequired,
  resources: shapes.resources,
  config: shapes.config,
};

PageFooter.defaultProps = {};

export default PageFooter;
