import React from 'react';
import CountUp from 'react-countup';

import styles from './Number.module.css';

const Number = ({ content, children, number }) => {
  if (!content?.replace('\n', '')) return null;
  return (
    <span className={styles.number}>
      <CountUp end={+number} duration={5} separator="." /> {children}
    </span>
  );
};

export default Number;
