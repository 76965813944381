import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PageNavigationMobile from './PageNavigationMobile';
import PageNavigationDesktop from './PageNavigationDesktop';
import {
  useResizeListener,
  translateRoute,
  renderRoutesRecursively,
} from '../utils';
import Icon from './Icon';
import Translate from './Translate';

import { navigate } from 'gatsby';
import logoHP from '../assets/logo.png';

const PageNavigation = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  const setMobile = () => {
    setIsMobile(
      window.innerWidth <= 768 ||
      (window.innerWidth < window.innerHeight && window.innerWidth <= 1024)
    );
  };
  useResizeListener(setMobile);

  const {
    pages,
    resources,
    config: {
      localization: { locale, locales },
      path,
    },
  } = props;

  const renderLogo = (styles) => {
    return <img className={styles.logo} src={logoHP} alt="Heid + Partner" onClick={() => navigate(`/${locale.code}`)} />;
  };

  const renderContact = (styles) => (
    <div className={styles.contact}>
      <Translate resources={resources} translate="navigation.contact" />
      <a href="tel:+49358176430">
        <Icon icon="phone" />
        +49 3581 7643 - 0
      </a>
      <a href="mailto:info@heidimmobilien.de">
        <Icon icon="email" />
        info@heidimmobilien.de
      </a>
      <Icon
        onClick={() => translateRoute(path, locale, locales)}
        className={styles.fab}
        icon="globe"
        role="button"
        tooltip={
          <Translate
            resources={resources}
            translate="navigation.button.translate"
          />
        }
      />
    </div>
  );

  const renderRoutes = (styles) => (
    <div className={styles.routes}>{renderRoutesRecursively(pages)}</div>
  );

  if (isMobile) {
    return (
      <PageNavigationMobile
        renderLogo={renderLogo}
        renderContact={renderContact}
        renderRoutes={renderRoutes}
      />
    );
  }

  return (
    <PageNavigationDesktop
      renderLogo={renderLogo}
      renderContact={renderContact}
      renderRoutes={renderRoutes}
      {...props}
    />
  );
};

const localeShape = PropTypes.shape({
  code: PropTypes.string.isRequired,
  localizedPaths: PropTypes.object.isRequired,
});

PageNavigation.propTypes = {
  contentful_id: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  resources: PropTypes.arrayOf(PropTypes.object).isRequired,
  config: PropTypes.shape({
    localization: PropTypes.shape({
      locale: localeShape.isRequired,
      locales: PropTypes.arrayOf(localeShape).isRequired,
    }).isRequired,
  }).isRequired,
};

PageNavigation.defaultProps = {};

export default PageNavigation;
