import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { pdf, Font } from '@react-pdf/renderer';
import styles from './EstatePrint.module.css';
import PDF from '../PDF';
import Icon from './Icon';
import { mergeClassNames } from '../utils';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import Perrywood from '../assets/fonts/Perrywood MT Regular.ttf';
import PTSans from '../assets/fonts/PT Sans.ttf';

const parseIfNecessary = (value) =>
  value?.json ? documentToPlainTextString(value.json, '\n') : value;

const EstatePrint = ({
  id,
  data,
  domain,
  title,
  locale = '',
  className = '',
}) => {
  const name = `HP-${id}-${locale}.pdf`;
  const [loading, setLoading] = useState(false);

  const transl = (key) => parseIfNecessary(data.translate(key));

  const generatePdfDocument = (data, name) => {
    setLoading(true);

    Font.register({
      family: 'Perrywood',
      src: Perrywood,
    });
    Font.register({
      family: 'PTSans',
      src: PTSans,
    });

    pdf(
      <PDF
        data={data}
        domain={domain}
        translate={transl}
        parseIfNecessary={parseIfNecessary}
      />
    )
      .toBlob()
      .then((blob) => saveAs(blob, name))
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  return (
    <Icon
      top
      role="button"
      title={title}
      onClick={() => generatePdfDocument(data, name)}
      className={mergeClassNames(styles.fab, className, {
        [styles.loading]: loading,
      })}
      tooltip={loading ? undefined : transl('common.print-pdf')}
      text="Exposé"
      icon={loading ? 'loader' : 'printer'}
    />
  );
};

export default EstatePrint;
