import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { mergeClassNames, useFetch, useTranslate } from '../utils';
import Button from './Button';
import styles from './ContactForm.module.css';
import Icon from './Icon';
import Modal, { useModal } from './Modal';
import Translate from './Translate';

export const Checkbox = forwardRef(
  ({ className, errors = {}, label, ...props }, ref) => {
    return (
      <div className={mergeClassNames(styles.input, className)}>
        <input type="checkbox" id={props.name} ref={ref} {...props} />
        <label htmlFor={props.name}>{label}</label>
        <span
          className={mergeClassNames(styles.error, {
            [styles.visible]: !!errors[props.name],
          })}
        >
          {errors[props.name]?.message}
        </span>
      </div>
    );
  }
);

export const Input = forwardRef(
  ({ className, Tag = 'input', errors = {}, isLoading, ...props }, ref) => {
    return (
      <div className={mergeClassNames(styles.input, className)}>
        <Tag ref={ref} {...props} />
        <span
          className={mergeClassNames(styles.error, {
            [styles.visible]: !!errors[props.name],
          })}
        >
          {errors[props.name]?.message}
        </span>
        {isLoading && <Icon
          className={styles.loader}
          icon="loader"
        />}
      </div>
    );
  }
);

const ContactForm = ({ resources, config }) => {
  const { register, handleSubmit, errors } = useForm();
  const translate = useTranslate(resources);
  const [{ response, isLoading, error }, fetchData] = useFetch();
  const [privacyModalIsVisible, togglePrivacyModal] = useModal();
  const [processingModalVisible, toggleProcessingModal] = useModal();

  const hasErrors = !!Object.keys(errors).length;

  const isProduction = config.env === 'production';

  const onSubmit = (data) => {
    if (!hasErrors) {
      const path = isProduction ? 'sendmail' : 'dev-sendmail';
      const options = {
        method: 'POST',
        body: JSON.stringify({ ...data, receiver: 'immo@heidimmobilien.de' }),
      };
      fetchData(`/${path}`, options);
    }
  };

  const getButtonText = () => {
    if (error) return translate('form.button.error');
    if (response) return translate('form.button.submitted');
    if (isLoading) return translate('form.button.submitting');
    return translate('form.button.send');
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
      id="contact-form"
    >
      <Input
        className={styles.inputFull}
        name="name"
        errors={errors}
        placeholder={`${translate('form.name')}*`}
        ref={register({
          required: translate('form.required'),
          minLength: {
            value: 2,
            message: translate('form.minlength'),
          },
          maxLength: {
            value: 36,
            message: translate('form.maxlength'),
          },
        })}
      />
      <Input
        className={styles.inputHalf}
        name="mail"
        errors={errors}
        placeholder={`${translate('form.email')}*`}
        ref={register({
          required: translate('form.required'),
          pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: translate('form.pattern.email'),
          },
          minLength: {
            value: 2,
            message: translate('form.minlength'),
          },
          maxLength: {
            value: 36,
            message: translate('form.maxlength'),
          },
        })}
      />
      <Input
        className={styles.inputHalf}
        name="phone"
        errors={errors}
        placeholder={translate('form.phone')}
        ref={register({
          minLength: {
            value: 2,
            message: translate('form.minlength'),
          },
          maxLength: {
            value: 36,
            message: translate('form.maxlength'),
          },
        })}
      />
      <Input
        Tag="textarea"
        className={styles.inputFull}
        name="message"
        rows={6}
        errors={errors}
        placeholder={`${translate('form.message')}*`}
        ref={register({
          required: translate('form.required'),
          minLength: {
            value: 5,
            message: translate('form.minlength'),
          },
          maxLength: {
            value: 256,
            message: translate('form.maxlength'),
          },
        })}
      />
      <Checkbox
        className={styles.checkbox}
        name="dataprivacy-disclaimer"
        label={translate('form.privacy-disclaimer')}
        errors={errors}
        ref={register({ required: translate('form.required') })}
        onClick={togglePrivacyModal}
      />
      <Modal isVisible={privacyModalIsVisible} hide={togglePrivacyModal}>
        <Translate
          resources={resources}
          config={config}
          translate="modal.privacy-disclaimer"
        />
      </Modal>
      <Checkbox
        className={styles.checkbox}
        name="processing-disclaimer"
        label={translate('form.processing-disclaimer')}
        errors={errors}
        ref={register({ required: translate('form.required') })}
        onClick={toggleProcessingModal}
      />
      <Modal isVisible={processingModalVisible} hide={toggleProcessingModal}>
        <Translate
          resources={resources}
          config={config}
          translate="modal.processing-disclaimer"
        />
      </Modal>
      <Button
        className={styles.submit}
        type="submit"
        disabled={hasErrors || isLoading}
      >
        {getButtonText()}
      </Button>
    </form>
  );
};

export default ContactForm;
