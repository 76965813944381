import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import * as shapes from './proptypes';
import Perrywood from '../assets/fonts/Perrywood MT Regular.woff2';
import PTSans from '../assets/fonts/PT Sans.woff2';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../index.css';
import '../variables.css';

import styles from './Layout.module.css';

const Layout = ({
  children,
  config,
  estateTitle,
  metadata: { title, description },
}) => {
  const {
    path,
    env,
    domain,
    localization: { locale },
  } = config;
  const isProduction = env === 'production';

  useEffect(() => {
    if (isProduction) {
      // disable console.log
      console.log = () => { };
      // disable console.info
      console.info = () => { };

      ReactGA.initialize('UA-76202457-3');
      ReactGA.set({ page: path, anonymizeIp: true });
      ReactGA.pageview(path);
    }
  }, [isProduction, path]);

  const enrichedTitle = title.replace('{{title}}', '... loading')

  const { navigation, footer, content } = children.reduce((acc, child) => {
    if (child.props.typename.match(/.*(navigation).*/i)) {
      return { ...acc, navigation: child };
    }
    if (child.props.typename.match(/.*(footer).*/i)) {
      return { ...acc, footer: child };
    }
    return { ...acc, content: [...(acc.content || []), child] };
  }, {});

  return (
    <div className={styles.root}>
      <Helmet>
        <html lang={locale.code} />
        <title>{enrichedTitle}</title>
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="preload"
          href={Perrywood}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={PTSans}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={enrichedTitle} />
        <meta property="og:site_name" content={enrichedTitle} />
        <meta property="og:url" content={path} />
        <meta property="og:image" content={`${domain}/logo.png`} />
      </Helmet>
      {navigation}
      <main className={styles.main}>{content}</main>
      {footer}
    </div>
  );
};

Layout.propTypes = {
  metadata: shapes.metadata,
  config: shapes.config,
  children: PropTypes.node,
};

Layout.defaultProps = {};

export default Layout;
